import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import CategoriesPage from './CategoriesPage';
import AdminPage from './AdminPage';
import EmailPage from './EmailPage';
import AboutMe from './AboutMe'
import PostPage from './PostPage'; 
import ComicsPage from './Categories/ComicsPage';
import EventsPage from './Categories/EventsPage';
import GamesPage from './Categories/GamesPage';
import MusicPage from './Categories/MusicPage';
import SportsPage from './Categories/SportsPage';
import './App.css';
import AboutClothes from './AboutMePages/AboutClothes';
import AboutMusic from './AboutMePages/AboutMusic';
import AboutVideos from './AboutMePages/AboutVideos';
import PastProject from './PastProjects';
import SendEmailPage from './SendEmailPage';
import ContactPage from './ContactPage';
import StickersPage from './StickersPage';

const App = () => {
  return (
    <Router>
      <div>
        <nav className="navbar">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/Email">Join Little Brother!</Link>
            </li>
            <li>
              <Link to="/stickers">Stickers</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </nav>

        <Routes>
          <Route path="/" element={<CategoriesPage />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/Email" element={<EmailPage />} />
          <Route path="/post" element={<PostPage />} />
          <Route path="/about" element={<AboutMe />} />
          <Route path="/music" element={<MusicPage />} />
          <Route path="/sports" element={<SportsPage />} />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/games" element={<GamesPage />} />
          <Route path="/comics" element={<ComicsPage />} />
          <Route path="/pastprojects" element={<PastProject />} />
          <Route path="/sendemail" element={<SendEmailPage />} />
          <Route path='/contact' element={<ContactPage/>} />
          <Route path='/stickers' element={<StickersPage/>} />

          <Route path="/myclothes" element={<AboutClothes />} />
          <Route path="/mymusic" element={<AboutMusic />} />
          <Route path="/myvideos" element={<AboutVideos />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;

